import { lazy, ReactElement, Suspense, useEffect, useRef, useState } from 'react';
import React from "react";

import { Skeleton } from 'antd';

import {
  BrowserRouter,
  Routes,
  Route,
  Link,
  useNavigate,
  useLocation,
} from 'react-router-dom'

import { BackDropLoading } from './Components/BackDrop-Loading';




const Sections = lazy(() =>
  import('./Pages/Sections')
    .then(({ Sections }) => ({ default: Sections }))
);

const Categories = lazy(() =>
  import('./Pages/Categories')
    .then(({ Categories }) => ({ default: Categories }))
);

const Locale = lazy(() =>
  import('./Pages/Locale')
    .then(({ Locale }) => ({ default: Locale }))
);

const ResponseBooking = lazy(() =>
  import('./Pages/ResponseBooking')
    .then(({ ResponseBooking }) => ({ default: ResponseBooking }))
);

const PropertyManager = lazy(() =>
  import('./Pages/PropertyManager')
    .then(({ PropertyManager }) => ({ default: PropertyManager }))
);

const Qr = lazy(() =>
  import('./Pages/Generate-qr')
    .then(({ Qr }) => ({ default: Qr }))
);

const Prenotato = lazy(() =>
  import('./Pages/Prenotato')
    .then(({ Prenotato }) => ({ default: Prenotato }))
);

const PrenotatoAddebitoAfineMese = lazy(() =>
  import('./Pages/Preonotato-AddebitoAFineMEse')
    .then(({ PrenotatoAddebitoAfineMese }) => ({ default: PrenotatoAddebitoAfineMese }))
);









export const Master = () => {


  const Number = useRef<number>(0)
  const [Number_, setNumber_] = useState<number>(0)

  const [Width_, setWidth_] = useState<number>(0)
  const [Language, setLanguage] = useState('en')
  const [id, setid] = useState(window.location.pathname.split('/')[1])
  const [Dati, setDati] = useState<any>(null)
  const [SessionID, setSessionID] = useState<any>(null)

  const renderLoader = () => <div style={{ display: "flex", flexFlow: "column nowrap", justifyContent: "center", alignItems: "center", height: "100vh" }}><Skeleton active style={{ width: "90%", margin: "2rem auto" }} /><Skeleton active style={{ width: "90%", margin: "2rem auto" }} /><Skeleton active style={{ width: "90%", margin: "2rem auto" }} /><Skeleton active style={{ width: "90%", margin: "2rem auto" }} /><Skeleton active style={{ width: "90%", margin: "2rem auto" }} /></div>;


  function setSessionId() {
    let SesId = generateSessionId(9)
    setSessionID(SesId)
    //console.log("SesId", SesId, new Date().getTimezoneOffset())
  }

  useEffect(() => {
    if (!window.location.pathname.includes('bookingresponse') && !window.location.pathname.includes('propertymanager')) {
      try {
        fetch('https://d5seqvdde6.execute-api.eu-south-1.amazonaws.com/prod/getdataapartment', {
          method: "POST",
          body: JSON.stringify({ Type: "getdataapartment", City: id.split('-')[0], PropertyManager: id.split('-')[1], Apartment: id.split('-')[2] })
        })
          .then(async (res) => {
            let response = await res.json()
            //console.log(response)
            setDati(response)
          })
      } catch (err) { console.log(err) }
    }

    setSessionId()

  }, [])




  if (!Dati && !window.location.pathname.includes('bookingresponse') && !window.location.pathname.includes('propertymanager')) { return <BackDropLoading loading={true}></BackDropLoading> }
  else {
    return (
      <>
        <div id='top'></div>

        <BrowserRouter>

          <Routes>
          <Route path={`/prenotato`} element={<Suspense fallback={renderLoader()}><Prenotato Language={Language} setLanguage={setLanguage} id={id} SessionID={SessionID}></Prenotato></Suspense>}></Route>
          <Route path={`/prenotato_2`} element={<Suspense fallback={renderLoader()}><PrenotatoAddebitoAfineMese Language={Language} setLanguage={setLanguage} id={id} SessionID={SessionID}></PrenotatoAddebitoAfineMese></Suspense>}></Route>

          
            <Route path={`/bookingresponse/*`} element={<Suspense fallback={renderLoader()}><ResponseBooking Language={Language} setLanguage={setLanguage} id={id} Dati={Dati}></ResponseBooking></Suspense>}></Route>
            <Route path={`/propertymanager/*`} element={<Suspense fallback={renderLoader()}><PropertyManager></PropertyManager></Suspense>}></Route>
            <Route path={`/${id}/*`} element={<Suspense fallback={renderLoader()}><Sections Language={Language} setLanguage={setLanguage} id={id} Dati={Dati} SessionID={SessionID}></Sections></Suspense>/*<Suspense fallback={renderLoader()}><Sections Language={Language} setLanguage={setLanguage} id={id} Dati={Dati}></Sections></Suspense>*/}></Route>
            <Route path={`/${id}/section/*`} element={<Suspense fallback={renderLoader()}><Categories Language={Language} setLanguage={setLanguage} id={id} Dati={Dati} SessionID={SessionID}></Categories></Suspense>}></Route>
            <Route path={`/${id}/locale/*`} element={<Suspense fallback={renderLoader()}><Locale Language={Language} setLanguage={setLanguage} id={id} Dati={Dati} SessionID={SessionID}></Locale></Suspense>}></Route>
            {<Route path={`/${id}/qr/*`} element={<Suspense fallback={renderLoader()}><Qr></Qr></Suspense>}></Route>}


          </Routes>

        </BrowserRouter>
      </>
    )

  }


  function generateSessionId(n: number) {
    var chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    var token = '';
    for (var i = 0; i < n; i++) {
      token += chars[Math.floor(Math.random() * chars.length)];
    }
    return token;
  }
}


